import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

const EvercareComponent = () => {
    const [files, setFiles] = useState([]);
    const { userGroup ,jwtToken, setAlertClassName, setAlertMessage, setAlertType } = useOutletContext();
    const [isLoading1, setIsLoading1] = useState(false); // Added loading state
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const formatDate = (inputDate) => {
        const [year, month, day] = inputDate.split('-');
        return `${month}/${day}/${year}`;
    };

    const handleCreateInvoice = async () => {
        const isConfirmed = window.confirm(`Are you sure you want to create an invoice for Evercare?`);
        if (isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/evercare/invoice/`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    const date = new Date();
                    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                    const filename = `evercare_${formattedDate}.csv`;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    // Remove the clients associated with the selected hospital from the data state
                    setData([]);

                } else {
                    console.error(`Failed to create invoice for hospital Status: ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to create invoice:", error);
            }
        }
};

    const handleDelete = async (id, name) => {
        if (userGroup !== "Manager") {
            setAlertClassName("col-md-6 offset-md-3");
            setAlertType('warning');
            setAlertMessage(`Sorry, you do not have the necessary permissions to delete this item.`);
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 4000);
            return;
        }
    
        const isConfirmed = window.confirm(`Are you sure you want to delete ${name}?`);
    
        if (isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/evercare/evercare_clients/${id}/`, {
                    method: 'DELETE',
                    headers: {
                        "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                        'Content-Type': 'application/json',
                    }
                });
    
                if (response.ok) {
                    setData(prevData => prevData.filter(row => row.id !== id));
                } else {
                    console.error(`Failed to delete client with ID ${id}. Status: ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to delete client:", error);
            }
        }
    };
    

    const loadData = async () => {
        if (!jwtToken) return; // Exit if no jwtToken
    
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/evercare/evercare_clients/`, {
                headers: {
                    'Authorization': `Token ${jwtToken}`
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const json = await response.json();
            setData(json);
        } catch (e) {
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        loadData();
    }, [jwtToken]); // Dependencies array


    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleSubmit = async () => {
        if (!jwtToken) {
            console.error('No JWT token found');
            setAlertClassName("text-danger");
            setAlertMessage("Unauthorized: Please log in to proceed.");
            setAlertType("error");
            return;
        }

        const formData = new FormData();

        Array.from(files).forEach(file => {
            formData.append('files', file);
        });

        try {
            if (files.length < 1){
                setAlertClassName("col-md-6 offset-md-3");
                setAlertMessage("Please add file(s) before proceeding with the processing.");

                setAlertType("warning");
                setTimeout(function () {
                    setAlertClassName("d-none");
                    setAlertMessage("");
                }, 5000);
                return 

            }
            setIsLoading1(true); // Start loading
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/evercare/upload_docs`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Token ${jwtToken}`,
                      
                },
            });

            if (!response.ok) {
                console.log(formData)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            setIsLoading1(false); 

            setAlertClassName("col-md-6 offset-md-3");
            setAlertMessage("Finished!");
            setAlertType("success");
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 5000);
            loadData();
        } catch (error) {
            setIsLoading1(false); 
            console.error('Error uploading files:', error);
            setAlertClassName("col-md-6 offset-md-3");
            setAlertMessage(`Error uploading files: ${error}`);
            setAlertType("error");
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 5000);
      
        }
    };
    // const totalCost = data.reduce((sum, client) => sum + parseFloat(client.cost || 0), 0);
    const totalCost = data.reduce((acc, client) => acc + parseFloat(client.cost), 0).toFixed(2); // Sum up all the costs

    return (
        <div className='container mt-5'>
            <div className='row offset-lg-2 px-4'>
            {isLoading1 && <h6 className="text-primary m-2">Please wait till documents are finished being processed</h6>}
                <div className='col-lg-8 '>
                    <label htmlFor="formFileMultiple" className="form-label text-white">Upload your Evercare PDFs</label>
                    <input className="form-control " type="file" id="formFileMultiple" multiple onChange={handleFileChange} />
                    <button 
                        className='btn btn-primary mt-3 mb-4 mt-1' 
                        onClick={handleSubmit} 
                        disabled={isLoading1}
                    >
                        Process documents
                    </button>

                    

                </div>
                <div className='col mt-5'>
                    {isLoading1 && <div className="spinner-border text-primary mx-5" aria-hidden="true"></div>}

                </div>

            <div className='row'>
                <div className='col'>
                    <button 
                        className="btn btn-outline-warning" 
                        onClick={handleCreateInvoice}
                        disabled={ userGroup !== "Manager" || data.length < 1}
                        style={{visibility: userGroup === "Manager" ? "visible" : "hidden"}}
                    >
                        Create Invoice
                    </button>

                </div>
                <div className='col mt-2'>
                    <span className="badge bg-primary p-2 text-lg"> ({data.length}) Total Trips</span>
                </div>
                <div className='col mt-2'>
                <span className='border border-1 border-success px-4 py-2 rounded-5 text-white '>Total Cost: ${totalCost}</span>
             
                </div>
        
                {isLoading ? (
                       <p className="card-text placeholder-glow">
                       <span className="placeholder col-12 mt-3 mb-1" style={{ height: '25px' }}></span>
                       <span className="placeholder col-12 mb-1 "  style={{ height: '35px' }}></span>
                       
                   </p>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <div className="table-responsive">
                    <table className='table table-hover table-striped table-dark mb-5 mt-3'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Destination</th>
                                <th>Miles</th>
                                <th>Cost</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((client, index) => ( // Use the index parameter here
                                <tr key={client.id}>
                                    <td>{index + 1}.</td> {/* Display the count */}
                                    <td>{client.name}</td>
                                    <td>{formatDate(client.date)}</td>
                                    <td>{client.destination}</td>
                                    <td>{client.miles_approved}</td>
                                    <td>{client.cost}</td>
                                    <td> 
                                        <i 
                                            className="btn bi bi-trash text-danger"
                                            onClick={() => handleDelete(client.id, client.name)}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                )}
            </div>
        
        </div>
    );
          
        </div>
    );
}

export default EvercareComponent;
