import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import './App.css';
import Alert from "./components/Alert";
import logOutNow from "./components/Logout";

function App() {
    const [jwtToken, setJwtToken] = useState("");
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const toggleDropdown = () => setIsDropdownActive(!isDropdownActive);
    const navigate = useNavigate()
    const [userGroup, setUserGroup] = useState(""); 
    const [alertMessage, setAlertMessage] = useState("")
    const [alertType, setAlertType] = useState("")
    const [alertClassName, setAlertClassName] = useState("d-none")

    useEffect(() =>{
      if (localStorage.getItem('jwt')) {
        setJwtToken(localStorage.getItem('jwt'));
        navigate("/form");
      }
    },[navigate])

    useEffect(() => {
      if (jwtToken && userGroup === "") { // Only make the API call if jwtToken is present and userGroup is an empty string
          fetch(`${process.env.REACT_APP_BACKEND}/api/user/group/`, {
              headers: {
                  'Authorization': `Token ${jwtToken}`,
              }
          })
          .then(res => res.json())
          .then(data => {
              setUserGroup(data.group);
              navigate("/form");
          })
          .catch(error => {
              console.error("Error fetching user groups:", error);
          });
      }
  }, [jwtToken, userGroup,navigate]); // useEffect will re-run if jwtToken or userGroup changes
  

    return (
        <>
       
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
                <div className="container-fluid">
                    <a className="navbar-brand ms-5 text-white p-3" href="/">
                        <span>
                            <i className="bi bi-lightning-fill text-warning"></i>
                        </span>
                        JL Dispatching Portal
                    </a>

                    {jwtToken !== '' && userGroup !== "" && (
                        <>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={toggleDropdown}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
                                <div className="navbar-nav">
                                    <Link to="/form" className="nav-link text-white px-lg-4">
                                        <i className="bi bi-person-fill d-lg-none pe-1 text-primary"></i> Form
                                    </Link>
                                    <Link to="/dashboard" className="nav-link text-white">
                                        <i className="bi bi-receipt d-lg-none pe-1 text-primary"></i> Dashboard
                                    </Link>

                                    <Link to="/billing-history" className="nav-link text-white px-lg-4">
                                        <i className="bi bi-clock-history d-lg-none pe-1 text-primary"></i> Billing History
                                    </Link>
      
                                      {userGroup ==="Manager" && (
                                         <Link to="/orange-works" className="nav-link text-white ">
                                         <i className="bi bi-arrow-left-circle d-lg-none pe-1 text-primary"></i> Orange Works
                                     </Link>

                                    )}
                                      {userGroup ==="Manager" && (
                                         <Link to="/admin" className="nav-link text-white ">
                                         <i className="bi bi-key-fill d-lg-none pe-1 text-primary"></i> Admin Panel
                                     </Link>

                                    )}
                                </div>
                            </div>

                            {!isDropdownActive && (
                                <div className="ms-auto">
                                    <button
                                        className="nav-link text-white pe-lg-5 pe-1 btn-link"
                                        onClick={() => {
                                          logOutNow(jwtToken);
                                          setJwtToken("");
                                          localStorage.removeItem("jwt");
                                          navigate("/");
                                          setAlertClassName("col-md-6 offset-md-3");
                                          setAlertType('success');
                                          setAlertMessage(`Your Logged Out!`);
                                          setTimeout(function () {
                                              setAlertClassName("d-none");
                                              setAlertMessage("");
                                          }, 4000);
                                       }}

                                    >
                                        <span className='border border-1 border-danger px-3 py-2 rounded rounded-2'>Logout</span>
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </nav>
            <Alert 
              message={alertMessage}
              className={alertClassName}
              alertType={alertType}
            ></Alert>

            <Outlet context={{ jwtToken, setJwtToken ,userGroup, setUserGroup,setAlertClassName, setAlertMessage, setAlertType}} />

            <Footer />
        </>
    );
}

export default App;
