import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {  useNavigate } from 'react-router-dom';
const OrangeWorksDashboard = () => {
    const { jwtToken, userGroup, setAlertClassName, setAlertMessage, setAlertType } = useOutletContext();
    const [data, setData] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/orange-works/orangeworksclients/`, {
                    headers: {
                        "Authorization": `Token ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setData(data); // Save the fetched data
            } catch (error) {
                console.error("Fetch error:", error);
            }
        };
        fetchData();
    }, [jwtToken]);

    // Use the formatDate function as it is if needed
    const formatDate = (inputDate) => {
        const [year, month, day] = inputDate.split('-');
        return `${month}/${day}/${year}`;
    };

    // Adjust the handleDelete function to work with the new data structure and endpoint
   
    const handleDelete = async (id, name) => {
        if (userGroup !== "Manager") {
            setAlertClassName("col-md-6 offset-md-3");
            setAlertType('warning');
            setAlertMessage(`Sorry, you do not have the necessary permissions to delete this item.`);
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 4000);
            return;
        }
    
        const isConfirmed = window.confirm(`Are you sure you want to delete ${name}?`);
    
        if (isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/orange-works/orangeworksclient/${id}/`, {
                    method: 'DELETE',
                    headers: {
                        "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                        'Content-Type': 'application/json',
                    }
                });
    
                if (response.ok) {
                    setData(prevData => prevData.filter(row => row.id !== id));
                } else {
                    console.error(`Failed to delete client with ID ${id}. Status: ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to delete client:", error);
            }
        }
    };

    const handleCreateInvoice = async () => {
        const isConfirmed = window.confirm(`Are you sure you want to create an invoice for Orange Works?`);
        if (isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/orange-works/invoice/`, {
                    method: 'GET',
                    headers: {
                        "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    const date = new Date();
                    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                    const filename = `orange_works_${formattedDate}.csv`;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    // Remove the clients associated with the selected hospital from the data state
                    setData([]);

                } else {
                    console.error(`Failed to create invoice for OrangeWorks Status: ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to create invoice:", error);
            }
        }
};




    const totalCost = data.reduce((acc, row) => acc + parseFloat(row.cost * row.legs ), 0).toFixed(2); // Sum up all the costs

    return (
        <div className="container">
            <div className='row'>
                <div className='col-md-1 mt-2 '>
                    <i className="btn bi bi-arrow-left-square text-warning fs-2" onClick={()=>{navigate("/orange-works")}}></i>
                </div>
                <div className='col'>
                    <h4 className='text-center mt-4 text-warning text-uppercase '>Orange Works</h4>
                </div>
            </div>

            <hr className='text-secondary mb-4'></hr>
            <div className='row'>
                <div className='col-md-4 mt-3 text-start'>
                <button 
                        className="btn btn-outline-warning" 
                        onClick={handleCreateInvoice}
                        disabled={ userGroup !== "Manager" || data.length < 1}
                        style={{visibility: userGroup === "Manager" ? "visible" : "hidden"}}
                    >
                        Create Invoice
                    </button>
                </div>
                <div className='col-md-4 mt-4 text-center'><span className="badge bg-primary fs-6">{`(${data.length})  Total Trips`}</span></div>
                <div className='col-md-4 mt-4 text-end'><span className='border border-1 border-success px-4 py-2 rounded-5 text-white '>Total Cost: ${totalCost}</span></div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-12 mt-2 mb-5">
                    <div className="table-responsive">
                        <table className="table table-hover table-striped table-dark mb-5 mt-3">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Pickup Location</th>
                                <th>Drop-off Location</th>
                                <th>Cost</th>
                                <th>Legs</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {data.map((client, index) => (
                                    <tr key={client.id}>
                                        <th scope="client">{index + 1}.</th>
                                        <td>{client.name}</td>
                                        <td>{formatDate(client.date)}</td>
                                        <td>{client.pickup_loc}</td>
                                        <td>{client.dropoff_loc}</td>
                                        <td>{client.cost}</td>
                                        <td>{client.legs}</td>
                                        <td>
                                            <i
                                                className="btn bi bi-trash text-danger"
                                                onClick={() => handleDelete(client.id, client.name)}

                                                style={{ cursor: "pointer" }}
                                            ></i>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrangeWorksDashboard;
