import React, { useEffect } from 'react';

const alertStyles = {
    success: {
        backgroundColor: "#9ae6b4",
        icon: "bi bi-check-circle-fill"
    },
    error: {
        backgroundColor: "#feb2b2",
        icon: "bi bi-exclamation-circle-fill"
    },
    warning: {
        backgroundColor: "#fbd38d",
        icon: "bi bi-exclamation-circle-fill"
    },
    info: {
        backgroundColor: "#90cdf4",
        icon: "bi bi-info-circle-fill"
    }
};

const Alert = (props) => {
    const style = alertStyles[props.alertType] || {};

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // for a smooth scroll effect
        });
    }, [props.message, props.alertType]);

    return (
        <div className={`alert ${props.className} text-start ps-4`} 
             role="alert" 
             style={{backgroundColor: style.backgroundColor}}>
            <i className={`${style.icon} text-dark me-2`}></i>
            {props.message}
        </div>
    );
}

export default Alert;
