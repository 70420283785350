


import React, { useState, useRef, useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';
import {  useOutletContext } from 'react-router-dom';
const  OrangeWorksPage = () => {
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({}); 
    const [showSuccess, setShowSuccess] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const {jwtToken,  setAlertClassName, setAlertMessage, setAlertType} = useOutletContext();
    const [allEntries, setAllEntries] = useState([]);

    useEffect(() => {
        const fetchAutocompleteData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/orange-works/autocomplete-data/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${jwtToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setAllEntries(data); // Save the data to allEntries
            } catch (error) {
                console.error("Fetch error:", error);
            }
        };

        fetchAutocompleteData();
    }, [jwtToken]); // Dependency array

    const [formData, setFormData] = useState({
        name: '',
        pickup_loc: '',
        dropoff_loc: '',
        cost: '',
        legs: '',
        date:'',
    });

    const firstAndLastNameRef = useRef(null);

 
    // onChange function remains unchanged

    const onChange = (e) => {
        const { value } = e.target;
        setInputValue(value); // Update the input field value
        setFormData(prevFormData => ({
            ...prevFormData,
            name: value // Directly update the name in formData when input changes
        }));

        if (!value) {
            setSuggestions([]);
        } else {
            const filteredSuggestions = allEntries
                .filter(entry => entry.name.toLowerCase().includes(value.toLowerCase()))
                .slice(0, 5);
            setSuggestions(filteredSuggestions);
        }
    };


    const onSelectSuggestion = (entry) => {
    setInputValue(entry.name);
    setFormData({ ...formData, ...entry });
    setSuggestions([]);
    firstAndLastNameRef.current.focus();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        };

    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = "Name is required";
        if (!formData.pickup_loc) errors.pickup_loc = "Pickup location is required";
        if (!formData.dropoff_loc) errors.dropoff_loc = "Dropoff location is required";
        if (!formData.cost) errors.cost = "Cost is required";
        if (!formData.legs) errors.legs = "Number of legs is required";
        if (!formData.date) errors.date = "Must add a Date";

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }; 
   

    const handleSubmit = (e) => {
        e.preventDefault(); 
        if (!validateForm()) {
            // If validation fails, prevent form submission and alert the user
            //alert("Please fill in all required fields.");
            return;
        }
        const entryExists = allEntries.some(entry =>
            entry.name === formData.name &&
            entry.pickup_loc === formData.pickup_loc &&
            entry.dropoff_loc === formData.dropoff_loc
        );
    
        console.log("Entry Exists:", entryExists);
    
        if (!entryExists) {
            const newEntry = {
                ...formData,
                id: Math.max(...allEntries.map(entry => entry.id), 0) + 1 // Assuming IDs are numeric
            };
            setAllEntries(prevEntries => {
                const updatedEntries = [...prevEntries, newEntry];
                console.log("Updated Entries:", updatedEntries);
                return updatedEntries;
            });

        } else {
            // Handle existing entry case
            console.log("Entry already exists. Not adding.");
        }
        // Send the data via a POST request
        fetch(`${process.env.REACT_APP_BACKEND}/orange-works/orangeworksclients/`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();  // Convert the response data to a JSON
        })
        .then(data => {
            // Handle the response data here
            console.log(data);

        })
        .catch(error => {
            console.error('Error:', error);
            setAlertClassName("col-md-6 offset-md-3");
            setAlertType('error');
            setAlertMessage(`Error could not input!    ${error}`);
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 5000);

        });
        

        setFormData(prevState => ({
            name: '',
            pickup_loc: '',
            dropoff_loc: '',
            cost: '',
            legs: '',
            date: prevState.date, // Preserve the date
        }));

        setInputValue('');
        setShowSuccess(true);
        setTimeout(function () {
            setShowSuccess(false);
        }, 1500);
        };

    return (
    <div className='container mt-4'>

    <div className='row d-flex justify-content-center'>
        <form onSubmit={handleSubmit} className='col-md-6  text-secondary border border-1 border-primary rounded-3 p-4'>
        <div className="mb-3">
            <label htmlFor="date" className="form-label">Choose a date:</label>
            <input 
                type="date"
                className="form-control mb-3"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
            />
            {formErrors.date && <div className="text-danger">{formErrors.date}</div>}
        </div>
        <label>Name:</label>
        <input
            type="text"
            className="form-control mb-3"
            value={inputValue}
            onChange={onChange}
            ref={firstAndLastNameRef}
            placeholder="Start typing a name..."
        />
        {formErrors.name && <div className="text-danger">{formErrors.name}</div>}
        {suggestions.length > 0 && (
            <ul className="list-group">
            {suggestions.map((entry, index) => (
                <li 
                key={index}
                className="list-group-item list-group-item-action bg-light border border-dark"
                onClick={() => onSelectSuggestion(entry)}
                >
                <div>{`Name: ${entry.name}`}</div>
                <div>{`Pickup: ${entry.pickup_loc}`}</div>
                <div >{`Dropoff: ${entry.dropoff_loc}`}</div>
                <div>{`Cost: $${entry.cost}`}</div>
                <div>{`Legs: ${entry.legs}`}</div>
                </li>
            ))}
            </ul>
        )}
         <div className="form-group">
          <label>Pickup Location:</label>
          <input
            type="text"
            className="form-control"
            name="pickup_loc"
            value={formData.pickup_loc}

            onChange={handleInputChange}
          />
          {formErrors.pickup_loc && <div className="text-danger">{formErrors.pickup_loc}</div>}
        </div>
        <div className="form-group">
          <label>Dropoff Location:</label>
          <input
            type="text"
            className="form-control"
            name="dropoff_loc"
            value={formData.dropoff_loc}
            onChange={handleInputChange}
          />
          {formErrors.dropoff_loc && <div className="text-danger">{formErrors.dropoff_loc}</div>}
        </div>
        <div className="form-group row">
            <div className='col-6'>
            <label>Cost:</label>
          <input
            type="text"
            className="form-control"
            name="cost"
            value={formData.cost}
            onChange={handleInputChange}
          />
          {formErrors.cost && <div className="text-danger">{formErrors.cost}</div>}

            </div>
            <div className='col-6'>
            <label>Legs:</label>
          <input
            type="text"
            className="form-control"
            name="legs"
            value={formData.legs}
            onChange={handleInputChange}
          />
          {formErrors.legs && <div className="text-danger">{formErrors.legs}</div>}
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <button type="submit" className="btn btn-primary mt-3">Submit</button>
            </div>
            <div className='col pt-3'>
                {showSuccess && (
                      <i className="bi bi-person-check-fill fs-4 text-success" > Success</i>
                )}
            </div>


        </div>
        </form>
    </div>
    <div className='row pt-3'>
            <div className='col-md-8'>
            <div className='btn btn-outline-warning mt-2' onClick={()=>{  navigate("/orange-works-dashboard")}}>Attested/Create Invoice</div>
            </div>
         
        </div>

    </div>
    );
};

export default  OrangeWorksPage;
