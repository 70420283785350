import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
const HomePage = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const fetchDataFromUrl = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/hello/`, {
                    method: "GET",
                    headers: {

                        "Content-Type": "application/json",
            
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                } 
            } catch (error) {
                console.log(error)
            }
        };

        fetchDataFromUrl();
        navigate("/");
    }, []);

   
    return (
        <></>
  
    );
  };
  
  export default HomePage;