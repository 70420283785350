import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Dashboard from "./components/Dashboard";
import ClientForm from "./components/ClientForm";
import Login from "./components/Login";
import BillingHistory from "./components/BillingHistory";
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import CreateAccount from './components/CreateAccount';
import Admin from './components/Admin';
import Evercare from './components/Evercare';
import HomePage from './components/HomePage';
import OrangeWorksPage from './components/OrangeWorks';
import OrangeWorksDashboard  from './components/OrangeWorksDashboard ';



const router = createBrowserRouter([
  {
    path:"/",
    element:<App/>,
    errorElement:<ErrorPage/>,
    children:[
      {index:true, element:<Login/>},
      {path:"/dashboard",
       element:<Dashboard/>
      },
      {path:"/form",
       element:<ClientForm/>
      }, 
      {path:"/billing-history",
       element:<BillingHistory/>
      },
      {path:"/logout",
       element:<Dashboard/>
      },
      {path:"/create-account",
       element:<CreateAccount/>
      },
      {path:"/admin",
       element:<Admin/>
      },
      {path:"/evercare",
      element:<Evercare/>
     },
     {path:"/login-page",
      element:<HomePage/>//special page not related
     },
     {path:"/orange-works",
      element:<OrangeWorksPage/>//special page not related
     },
     {path:"/orange-works-dashboard",
      element:<OrangeWorksDashboard />
     },
   
     
    ]
  }

])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <RouterProvider router={router} />


);


