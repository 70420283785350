import React, { useState, useEffect } from 'react';
import {  useOutletContext } from 'react-router-dom';

const BillingHistory = () => {
    const [billedTotals, setBilledTotals] = useState([]);
    const {jwtToken, userGroup,  setAlertClassName, setAlertMessage, setAlertType} = useOutletContext();
    const [isLoading, setIsLoading] = useState(true); // Added loading state

   

    const formatDate = (inputDate) => {
      const [year, month, day] = inputDate.split('-');
      return `${month}/${day}/${year}`;
  };
  

  useEffect(() => {
    const fetchBilledTotalsData = async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/billed_totals/`, {
                headers: {
                    "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                    'Content-Type': 'application/json',
                }
            });
            const result = await response.json();
            setBilledTotals(result);
            setIsLoading(false); 
            
        } catch (error) {
            console.error("Failed to fetch billed totals:", error);
            setAlertClassName("col-md-6 offset-md-3");
            setAlertType('error');
            setIsLoading(false); 
            setAlertMessage("Failed to fetch billed totals:", error);
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 7000);
           
        }
    };

    fetchBilledTotalsData();
},  [setAlertClassName, setAlertMessage, setAlertType, jwtToken]);

const handleDelete = async (id, company) => {
    if (userGroup !=="Manager"){
        setAlertClassName("col-md-6 offset-md-3");
        setAlertType('warning');
        setAlertMessage(`Sorry, you do not have the necessary permissions to delete this item.`);
        setTimeout(function () {
            setAlertClassName("d-none");
            setAlertMessage("");
        }, 4000);
        return ;
    }
    const isConfirmed = window.confirm(`Are you sure you want to delete the billed total for ${company}?`);

    if (isConfirmed) {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/billed_totals/${id}/`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Token ${jwtToken}`, // Ensure jwtToken is in scope
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                setBilledTotals(prevData => prevData.filter(bill => bill.id !== id));
            } else {
                console.error(`Failed to delete billed total with ID ${id}. Status: ${response.status}`);
                setAlertClassName("col-md-6 offset-md-3");
                setAlertType('error');
                setAlertMessage(`Failed to delete billed total with ID ${id}. Status: ${response.status}`);
                setTimeout(function () {
                    setAlertClassName("d-none");
                    setAlertMessage("");
                }, 7000);
            }
        } catch (error) {
            console.error("Failed to delete billed total:", error);
            setAlertClassName("col-md-6 offset-md-3");
            setAlertType('error');
            setAlertMessage("Failed to delete billed total:", error);
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 7000);
        }
    }
};


    return (
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-md-8 mt-4 mb-5">
                  <h5 className='text-center text-primary'>Billing History</h5>
                  <div className="table-responsive">
                {!isLoading ? (
                     <table className="table table-hover table-striped table-dark mb-5 mt-3">
                     <thead>
                         <tr>
                             <th></th>
                             <th>Company</th>
                             <th>Cost</th>
                             <th>Date</th>
                             <th></th>
                         </tr>
                     </thead>
                     <tbody>
                         {billedTotals.map((bill, index) => (
                             <tr key={bill.id}>
                                 <th scope="row">{index + 1}.</th>
                                 <td>{bill.company}</td>
                                 <td>{bill.cost}</td>
                                 <td>{formatDate(bill.date)}</td>
                                 <td>
                                     <i 
                                         className="btn bi bi-trash text-danger"
                                         onClick={() => handleDelete(bill.id, bill.company)}
                                         style={{ cursor: "pointer" }}
                                     ></i>
                                 </td>
                             </tr>
                         ))}
                     </tbody>
                 </table>
                ) : (
                    <p className="card-text placeholder-glow">
                    <span className="placeholder col-12 mt-3 mb-1" style={{ height: '25px' }}></span>
                    <span className="placeholder col-12 mb-1 "  style={{ height: '35px' }}></span>
                    <span className="placeholder col-12 mb-1" style={{ height: '35px' }}></span>
                    <span className="placeholder col-12 mb-1" style={{ height: '35px' }}></span>
                    <span className="placeholder col-12 mb-1" style={{ height: '35px' }}></span>
                    <span className="placeholder col-12 mb-1 " style={{ height: '35px' }}></span>
                    <span className="placeholder col-12 mb-1" style={{ height: '35px' }}></span>
                    <span className="placeholder col-12 mb-1 " style={{ height: '30px' }}></span>
                    
                </p>
                )}
                    </div>
                </div>
            </div>
        </div>
    );
};


  export default BillingHistory;
  