import { useState, useEffect, useCallback  } from "react";
import Input from "./form/Input";
import { useNavigate, useOutletContext } from "react-router-dom";
import logOutNow from "./Logout";


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const {setJwtToken} = useOutletContext();
    const {setAlertClassName, setAlertMessage, setAlertType, setUserGroup, jwtToken} = useOutletContext();

 
    const navigate = useNavigate();
    
    // Using useCallback to memorize the NotVerfiedUser function
    const NotVerfiedUser = useCallback(() => {
        setJwtToken("");
        localStorage.removeItem("jwt");
        navigate("/");
        setAlertClassName("col-md-6 offset-md-3");
        setAlertType('warning');
        setAlertMessage(`Access Pending: Awaiting manager's approval for clearance.`);
        setTimeout(function () {
            setAlertClassName("d-none");
            setAlertMessage("");
        }, 7000);
    }, [navigate, setJwtToken, setAlertClassName, setAlertType, setAlertMessage]);

   

    
    const fetchUserGroup = async (authToken) => { // accept authToken as an argument
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/group/`, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                }
            });
            const data = await response.json();
            setUserGroup(data.group);
            if (data.group === "Manager" || data.group === "Data Clerk") {
                localStorage.setItem("jwt", authToken);
                setJwtToken(authToken);
                navigate("/form");
                setAlertClassName("col-md-6 offset-md-3");
                setAlertType('success');
                setAlertMessage(`Your Logged In!`);
                setTimeout(function () {
                    setAlertClassName("d-none");
                    setAlertMessage("");
                }, 4000);
            } else {
               
                logOutNow(authToken);
                NotVerfiedUser();
            }
        } catch (error) {
            console.error("Error fetching user groups:", error);
        }
    };

    

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = `${process.env.REACT_APP_BACKEND}/auth/token/login/`;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            const authToken = data.auth_token;
            await fetchUserGroup(authToken); // Call the fetchUserGroup function with authToken
            
        } else {
            setAlertClassName("col-md-6 offset-md-3");
            setAlertType('error');
            setAlertMessage(`Invalid Credentials!`);
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 4000);
        }
    }
    
    return (
       
        <div className="container">
            <div className="row  d-flex justify-content-center  mt-4"> 
            <div className="col-md-5 mt-4 mb-5 border border-1 border-primary p-4 rounded rounded-3">
        <h3 className="mt-1 text-white">Login</h3>
      
        <form onSubmit={handleSubmit} className="text-secondary">
            <Input
                title="Email Address"
                type="email"
                className="form-control bg-light  border-1 border-secondary-subtle"
                name="email"
                autoComplete="email-new"
                onChange = {(event) => setEmail(event.target.value)}
            />
            <Input
                title="Password"
                type="password"
                className="form-control bg-light border-1 border-secondary-subtle"
                name="password"
                autoComplete="password-new"
                onChange = {(event) => setPassword(event.target.value)}
            />

            <div className="text-center">
              <input
                type="submit"
                className="btn btn-primary px-5 mb-2"
                value="Next"
              />
            </div>
        </form>
       
        <div className="text-center">
          <div className="btn btn-outline-primary px-5  mt-4 mb-sm-5 mb-md-0" onClick={() =>{ navigate('/create-account')}}>Create Account</div>
        </div>
    </div>
        
             </div>
        </div>
    
    )

}

export default Login ;
