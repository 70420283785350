const logOutNow = async (jwtToken) => {
    const url = `${process.env.REACT_APP_BACKEND}/auth/token/logout/`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${jwtToken}`,
        },
    });

    if (response.ok) {
        console.log("Logged out successfully");
    } else {
        console.error("Logout failed");
    }
}

export default logOutNow;
