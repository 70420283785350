const Footer = () => {
  return (
    <footer className="navbar navbar-dark bg-dark p-4 text-white mt-5">
      <div className="container d-flex justify-content-center justify-content-lg-end">
        <small>Jl Dispatching &copy; {new Date().getFullYear()}</small>
      </div>
    </footer>
  );
};

export default Footer;
