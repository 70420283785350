import { useRouteError } from "react-router-dom";

export default function ErrorPage(){
    const error = useRouteError();
    return (
        <div className="container">
            <div className="row">
            <div className="col-6 text-center offset-3 mt-4">
                <h1 className="text-white mt-3">Opps</h1>
                <p className="text-white">Sorry an unexpected error occurred</p>
                <p className="text-danger">
                    <em>{error.statusText || error.message}</em>
                </p>
            </div>
            </div>
        </div>
    )
}