const Select = (props) =>{
    return (
        <div className="mb-1 mb-md-3">
            <label htmlFor={props.name} className="form-label">
                {props.title}
            </label>
            <select 
                className="form-select bg-light border border-1 border-secondary-subtle"
                name = {props.name}
                id = {props.name}
                value={props.value}
                onChange= {props.onChange}

            >
                <option value="">{props.placeHolder}</option>
                {props.options.map((option) => {
            return (
                <option
                key={option.value} 
                value={option.value} 
                >
                    {option.label} 
                </option>
                )
            })}
            </select>
            <div className={props.errorDiv}>{props.errorMsg}</div>

        </div>
    )

}
export default Select; 