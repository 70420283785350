import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
const CreateAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
 
  const {setAlertClassName, setAlertMessage, setAlertType} = useOutletContext();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
      password: Yup.string()
        .min(7, 'Password should be at least 7 characters')
        .required('Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (Object.keys(formik.errors).length) {
        setAlertClassName("col-md-6 offset-md-3");
        setAlertType('warning');
        setAlertMessage(`Invalid Form`);
        setTimeout(function () {
            setAlertClassName("d-none");
            setAlertMessage("");
        }, 7000);
      } else {
        // Create a JavaScript object
        const formData = {
          username: values.email,
          password: values.password,
          email: values.email,
        };

        // Send the POST request
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/auth/users/`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
          });
      
          const data = await response.json();
      
          // Check if the response was successful
          if (response.ok) {
              setAlertClassName("col-md-6 offset-md-3");
              setAlertType('success');
              setAlertMessage('Your account has been successfully created! You can now log in with your credentials.');
              resetForm();
          } else {
              // Assuming error message is in the form { "email": ["Error message."] }
              // This will extract the first error message for simplicity.
              const firstKey = Object.keys(data)[0];
              const errorMessage = data[firstKey][0];
      
              setAlertClassName("col-md-6 offset-md-3");
              setAlertType('error');
              setAlertMessage(errorMessage);
          }
      
      } catch (error) {
          setAlertClassName("col-md-6 offset-md-3");
          setAlertType('error');
          setAlertMessage('An unexpected error occurred. Please try again.');
      }
      
      setTimeout(function () {
          setAlertClassName("d-none");
          setAlertMessage("");
      }, 7000);

  
      }
    },
  });
  return (
    <>
      <div className="container-fluid border-1 rounded-2 border-primary">
        <div className='row mt-2 d-flex justify-content-center '>
           <div className='col-md-5 mt-5 mb-5 border border-1 border-primary p-4 rounded rounded-3'>

        <form onSubmit={formik.handleSubmit}>
            <div className='text-center '>
                 <h2 className='text-white'>Create Your Account</h2>
                <p className='text-secondary'>If you have an account click here   <Link to="/"><span className='text-wa'><i className="bi bi-arrow-right-circle-fill"></i></span> </Link></p>
                   
            </div>
            
          <div className="form-group">
            <label htmlFor="email" className='text-secondary'>Email Address</label>
            <input
              id="email"
              type="email"
              {...formik.getFieldProps('email')}
              className={`form-control bg-light border-1 border-secondary-subtle ${formik.errors.email ? 'is-invalid' : ''}`}
            />
            {formik.errors.email ? <div className="invalid-feedback">{formik.errors.email}</div> : null}
          </div>

          <div className="form-group">
            <label htmlFor="password" className='text-secondary'>Password</label>
            <div className="input-group">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                {...formik.getFieldProps('password')}
                className={`form-control bg-light border-1 border-secondary-subtle ${formik.errors.password ? 'is-invalid' : ''}`}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <span className='text-primary'>{showPassword ? <FaEyeSlash /> : <FaEye />}</span>
                </button>
              </div>
              {formik.errors.password ? <div className="invalid-feedback">{formik.errors.password}</div> : null}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword" className='text-secondary'>Confirm Password</label>
            <div className="input-group">
              <input
                id="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                {...formik.getFieldProps('confirmPassword')}
                className={`form-control bg-light border-1 border-secondary-subtle ${formik.errors.confirmPassword ? 'is-invalid' : ''}`}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <span className='text-primary'>{showPassword ? <FaEyeSlash /> : <FaEye />}</span>
                </button>
              </div>
              {formik.errors.confirmPassword ? <div className="invalid-feedback">{formik.errors.confirmPassword}</div> : null}
            </div>
          </div>

          <button type="submit" className="btn btn-primary mt-4 mb-2">Create Account</button>
          
        </form>
        </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
