
import React from 'react';
const Admin = () => {
    return (
        <iframe 
            src="https://api.jldispatchwebportal.com/admin/" 
            title="Admin Panel"
            style={{ width: '100%', height: '80vh', border: 'none' }} // Adjust width, height, and other styles as needed
        ></iframe>
    );
}

export default Admin;
