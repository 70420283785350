
import React, { useState, useRef, useEffect } from 'react';
import Select from "./form/Select";
import Input from "./form/Input";

import {  useOutletContext } from 'react-router-dom';


const ClientForm = () => {

 
    // State management for form fields
    const [hospitals, setHospitals] = useState([]);
    const [selectedHospital, setSelectedHospital] = useState('');
    const [clientName, setClientName] = useState('');
    const [date, setDate] = useState('');
    const [destination, setDestination] = useState('');
    const clientNameRef = useRef();
    const destinationRef = useRef();
    const [formErrors, setFormErrors] = useState({});
    const {jwtToken,  setAlertClassName, setAlertMessage, setAlertType} = useOutletContext();
   
   
    

    const validateForm = () => {
        let errors = {};
        if (!selectedHospital || selectedHospital === '') errors.hospital = "Hospital is required.";
        if (!clientName || clientName.trim() === '') errors.clientName = "Client name is required.";
        if (!date) errors.date = "Date is required.";
        if (!destination || destination.trim() === '') errors.destination = "Destination is required.";
    
        setFormErrors(errors);
        return Object.keys(errors).length === 0; // If no errors, return true.
    };
    const resetForm = () => {
        // setSelectedHospital(''); 
        setClientName(''); 
        setDate(''); 
        destinationRef.current.value = "";
        setDestination('');
    };
    
    
    


    useEffect(() => {
        const fetchDataFromUrl = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/hospitals/`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Token ${jwtToken}`,
                        "Content-Type": "application/json",
            
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setHospitals(data);
                } 
            } catch (error) {
           
                setAlertClassName("col-md-6 offset-md-3");
                setAlertType('error');
                setAlertMessage("Error:", error.message);
                setTimeout(function () {
                    setAlertClassName("d-none");
                    setAlertMessage("");
                }, 7000);
                
            }
        };

        fetchDataFromUrl();
    }, [jwtToken, setAlertClassName, setAlertMessage, setAlertType]);


    useEffect(() => {
        const waitForGoogleMaps = () => {
            if (window.google && window.google.maps && window.google.maps.places) {
                initAutocomplete();
            } else {
                requestAnimationFrame(waitForGoogleMaps);  // Check again
            }
        };
    
        const initAutocomplete = () => {
            const input = destinationRef.current;
        
            if (!input) {
                console.error("Destination input not found");
                return;
            }
        
            const center = new window.google.maps.LatLng(41.3751, -74.6927);
            const circle = new window.google.maps.Circle({
                center: center,
                radius: 321869,
            });
        
            const autocomplete = new window.google.maps.places.Autocomplete(input, {
                bounds: circle.getBounds(),
            });
        
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.log("No details available for input: ", place.name);
                    return;
                }
                const address = place.formatted_address;
                setDestination(address);  // Set the state here
            });
        };
        
        if (!window.google) {
            console.error("Google Maps JavaScript API not loaded");
            waitForGoogleMaps();  // Start checking
        } else {
            initAutocomplete();
        }
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) return;  // If form is not valid, stop here.
        setAlertClassName("col-md-6 offset-md-3");
        setAlertType('success');
        setAlertMessage(`Trip successfully created for ${clientName}.`);
        resetForm();

        setTimeout(function () {
            setAlertClassName("d-none");
            setAlertMessage("");
        }, 5000);

        let processedDestination = destination.replace(/, USA$/, '');

        // Prepare the data to be sent
        const formData = {
            company: selectedHospital,
            name: clientName,
            date: date,
            destination: processedDestination
        };

        // Send the data via a POST request
        fetch(`${process.env.REACT_APP_BACKEND}/clients`, {
            method: 'POST',
            headers: {
                "Authorization": `Token ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();  // Convert the response data to a JSON
        })
        .then(data => {
            // Handle the response data here
            console.log(data);

        })
        .catch(error => {
            console.error('Error:', error);
            setAlertClassName("col-md-6 offset-md-3");
            setAlertType('error');
            setAlertMessage(`Error could not input (${clientName})!    ${error}`);

            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 5000);
        });
    };

    return (
        <div className='container mt-4'>
             <div className='row d-flex justify-content-center'>
                <div className='col-md-6 text-secondary border border-1 border-primary rounded-3 p-4'>
                <form onSubmit={handleSubmit}>

            <Select 
                name="hospitalSelect"
                title="Choose a Hospital:"
                value={selectedHospital}
                onChange={(e) => setSelectedHospital(e.target.value)}
                options={hospitals.map(hospital => ({ value: hospital.id, label: hospital.company }))}
                placeHolder="Select a hospital"
                errorDiv={formErrors.hospital ? "text-danger" : "d-none"}
                errorMsg={formErrors.hospital}
            />

            <Input 
                ref={clientNameRef}
                name="clientName"
                title="Client name:"
                type="text"
                className="form-control"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                errorDiv={formErrors.clientName ? "text-danger" : "d-none"}
                errorMsg={formErrors.clientName}
            />

            <div className="mb-1 mb-md-3">
                <label htmlFor="date" className="form-label">Choose a date:</label>
                <input 
                    type="date"
                    className="form-control"
                    id="date"
                    name="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    
                />
                <div className={formErrors.date ? "text-danger" : "d-none"}>{formErrors.date}</div>
            </div>

            <Input 
                ref={destinationRef}
                name="destination"
                title="Destination:"
                type="text"
                className="form-control"
                errorDiv={formErrors.destination ? "text-danger" : "d-none"}
                errorMsg={formErrors.destination}
            />
            <div className='d-flex justify-content-center'>
            <button type="submit" className="btn btn-primary px-5 py-3 my-4">Submit</button>

            </div>
           
        </form>
                    
                </div>
             </div>

        </div>
       
    );
};

export default ClientForm;
