import { useEffect, useState } from 'react';
import {  useOutletContext } from 'react-router-dom';


const Dashboard = () => {
    const {jwtToken, userGroup,  setAlertClassName, setAlertMessage, setAlertType} = useOutletContext();
    const [hospitals, setHospitals] = useState([]);
    const [data, setData] = useState([]);
    const [selectedHospitalFilter, setSelectedHospitalFilter] = useState(null);

    const formatDate = (inputDate) => {
        const [year, month, day] = inputDate.split('-');
        return `${month}/${day}/${year}`;
    };
    

    const handleCreateInvoice = async () => {
        if (selectedHospitalFilter) {
            const isConfirmed = window.confirm(`Are you sure you want to create an invoice for ${selectedHospitalFilter.company}?`);
            if (isConfirmed) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND}/invoice/${selectedHospitalFilter.id}/`, {
                        method: 'GET',
                        headers: {
                            "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                            'Content-Type': 'application/json',
                        }
                    });
                    if (response.ok) {
                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        const date = new Date();
                        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                        const filename = `${selectedHospitalFilter.company.replace(/\s+/g, '_')}_${formattedDate}.csv`;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
        
                        // Remove the clients associated with the selected hospital from the data state
                        setData(prevData => prevData.filter(client => client.company !== selectedHospitalFilter.id));
        
                    } else {
                        console.error(`Failed to create invoice for hospital ID ${selectedHospitalFilter.id}. Status: ${response.status}`);
                    }
                } catch (error) {
                    console.error("Failed to create invoice:", error);
                }
            }
        }
    };
    
    

    const handleDelete = async (id, name) => {
        if (userGroup !== "Manager") {
            setAlertClassName("col-md-6 offset-md-3");
            setAlertType('warning');
            setAlertMessage(`Sorry, you do not have the necessary permissions to delete this item.`);
            setTimeout(function () {
                setAlertClassName("d-none");
                setAlertMessage("");
            }, 4000);
            return;
        }
    
        const isConfirmed = window.confirm(`Are you sure you want to delete ${name}?`);
    
        if (isConfirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/clients/${id}/`, {
                    method: 'DELETE',
                    headers: {
                        "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                        'Content-Type': 'application/json',
                    }
                });
    
                if (response.ok) {
                    setData(prevData => prevData.filter(row => row.id !== id));
                } else {
                    console.error(`Failed to delete client with ID ${id}. Status: ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to delete client:", error);
            }
        }
    };
    

    useEffect(() => {
        const fetchClientsData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/clients`, {
                    headers: {
                        "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                        'Content-Type': 'application/json',
                    }
                });
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error("Failed to fetch clients:", error);
            }
        };
    
        const fetchHospitalsData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/hospitals/`, {
                    headers: {
                        "Authorization": `Token ${jwtToken}`, // Assuming jwtToken is in scope
                        'Content-Type': 'application/json',
                    }
                });
                const result = await response.json();
                setHospitals(result);
            } catch (error) {
                console.error("Failed to fetch hospitals:", error);
               
            }
        };
    
        fetchClientsData();
        fetchHospitalsData();
    }, [jwtToken]);
    
    const filteredData = selectedHospitalFilter
        ? data.filter(row => row.company === selectedHospitalFilter.id)
        : data;

    const totalCost = filteredData.reduce((acc, row) => acc + parseFloat(row.cost), 0).toFixed(2); // Sum up all the costs


    return (
        <div className="container">
          
            
            <div className="row justify-content-center align-items-center">
                <div className="col text-center">
                {hospitals.map((hospital) => (
                <button 
                    key={hospital.id} 
                    className={`btn ${selectedHospitalFilter === hospital ? 'btn-primary' : 'btn-outline-primary'} m-2 text-white`}
                    onClick={() => setSelectedHospitalFilter(hospital)}
                >
                    {hospital.company}
                </button>
            ))}
            <button 
                className={`btn ${!selectedHospitalFilter ? 'btn-primary' : 'btn-outline-secondary'} m-2`}
                onClick={() => setSelectedHospitalFilter(null)}
            >
                Show All
            </button>
                </div>
            </div>
            <hr className='text-white'></hr>

            <div className="row d-flex justify-content-center">

                <div className="col-md-8 mt-4 mb-5">
                    <div className="table-responsive">
                    <h5 className={`text-center mt-3 mb-4 ${selectedHospitalFilter ? 'text-primary' : 'text-primary'}`}>
                        {selectedHospitalFilter ? <span className="badge bg-primary">{`(${filteredData.length})  ${selectedHospitalFilter.verbose_name} Trips`}</span>
                            : <span className="badge bg-primary">{`(${filteredData.length})  Total Trips`}</span>
                        }
                    </h5>
                    <div className="d-flex justify-content-between align-items-center mb-2">

                    {
                        selectedHospitalFilter && selectedHospitalFilter.company !== "All"  ? (
                            <button 
                                className="btn btn-outline-warning" 
                                onClick={handleCreateInvoice}
                                disabled={!selectedHospitalFilter || filteredData.length === 0 || userGroup !== "Manager"}
                                style={{visibility: userGroup === "Manager" ? "visible" : "hidden"}}
                            >
                                Create Invoice
                            </button>

                        ) : (
                            <i className="bi bi-lightning-fill text-warning px-3 border border-warning rounded-2 p-1"></i>
                        )
                    }


                        <span className='border border-1 border-success px-4 py-2 rounded-5 text-white'>Total Cost: ${totalCost}</span>
                    </div>

                        <table className="table table-hover table-striped table-dark mb-5 mt-3">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Company</th>
                                    <th>Destination</th>
                                    <th>Cost</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((row, index) => (
                                    <tr key={row.id}>
                                        <th scope="row">{index + 1}.</th>
                                        <td>{row.name}</td>
                                        <td>{formatDate(row.date)}</td>
                                        <td>{hospitals.find(hospital => hospital.id === row.company)?.verbose_name || 'Unknown'}</td>
                                        <td>{row.destination}</td>
                                        <td>{row.cost}</td>
                                        <td> 
                                            <i 
                                                className="btn bi bi-trash text-danger"
                                                onClick={() => handleDelete(row.id, row.name)}

                                                style={{ cursor: "pointer" }}
                                            ></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
